.landing-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Was -1, changed to 0 to be at least on the same level as the base content */
}


.fade-section {
  position: absolute;
  bottom: 20%; /* Adjust based on the height of the text-section */
  width: 100%;
  height: 35%;
  background: linear-gradient(to bottom, transparent, black 75%);
  z-index: 1;
}

.text-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 10%;
  background: black;
  color: white;
  text-align: center;
  z-index: 1;
}

.distance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Adjust the spacing from the button as needed */
}

.distance-text {
  color: #00AFF0 ;
  font-style: italic;
  font-size: 17px; /* Adjust the font size as needed */
  margin-left: 1px; /* Spacing between the icon and the text */
}

.location-icon {
  width: 18px; /* Adjust the size as needed */
  height: 18px;
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(31, 33, 37);
  color: rgb(254, 254, 254);
  font-family: 'Poppins', sans-serif;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  min-height: 65px;
  padding: 8px;
  margin: auto;
  padding-bottom: ;
  border: 2px solid rgb(254, 254, 254);
  border-radius: 36px;
  text-decoration: none;
  width: 80vw;
}

.top-fade-text {
  padding-top: 20px;
  border-radius: 10px;
  color: rgb(254, 254, 254);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%; /* Slightly lower than the very top */
  width: 100%; /* Ensure it spans the entire width */
  text-align: center; /* Center the text horizontally */

}

.mid-fade-text {
  border-radius: 36px;
  color: rgb(254, 254, 254);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-top: 50px;
  margin-bottom: 4px;

  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%; /* Centered within the fade section */
  width: 100%; /* Ensure it spans the entire width */
  text-align: center; /* Center the text horizontally */
  transform: translateY(-50%); /* Center the text vertically within its space */
}


.button-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.button-icon {
  margin-right: 10px; /* Maintain spacing between the icon and the text */
}

.button-icon img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the start (left) */
  flex-grow: 1;
}

